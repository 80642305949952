<template>
  <div class="loading-page">
    <van-loading type="spinner" />
  </div>
</template>

<script>
import { Loading } from "vant";
export default {
  name: "CashierHandlePage",
  components: {
    [Loading.name]: Loading,
  },
  created() {
    let payUrl = this.$route.query.payUrl;
    window.location.replace(payUrl);
  },
};
</script>

<style lang="less" scoped>
.loading-page {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>